import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { GetGogeta } from '../sections/common/GetGogeta/GetGogeta';
import { Hero } from '../sections/EmployeeProduct/Hero';
import { EmployerSchemas } from '../sections/EmployerProduct/Schemas';
import {SEO} from "../components/Seo/Seo";

const EmployerProduct = () => {
	return (
		<MainLayout>
			<ScPage>
				<Hero isEmployer />
				<GetGogeta isEmployer />
				<EmployerSchemas />
			</ScPage>
		</MainLayout>
	);
};

export default EmployerProduct;

export const Head = () => (
	<SEO
		title="Employer Solutions | Gogeta Salary Sacrifice Products"
		description="Discover Gogeta’s salary sacrifice products for employers. Offer tax-saving benefits to employees with simple setup, minimal admin, and maximum impact on retention."
	/>
)
